<template>
    <el-dialog
            title="上传附件"
            :visible="dialogVisible"
            width="25%"
            center
            :open-delay="openDialog"
            :show-close="displayClose">
        <el-upload
                class="upload-demo"
                drag
                ref="upload"
                style="text-align: center"
                :action="uploadPath"
                :on-remove="removeFile"
                :on-success="getData"
                :on-error="getErrorData"
                multiple>
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        </el-upload>
        <span slot="footer" class="dialog-footer">
            <el-button @click="cancelUpload">取 消</el-button>
            <el-button type="primary" @click="saveFiles">确 定</el-button>
        </span>
    </el-dialog>
</template>

<script>
    var urlPath = process.env.VUE_APP_CURENV;
    export default {
        name: "UploadFiles",
        props: ['dialogVisibles','fileType','fileId'],
        data(){
            return{
                filename:'',
                displayClose:false,
                dialogVisible: false,
                businessType: '',
                attachmentList:[],
                attachmentListStr:'',
                id:'',
                removeStr:'',
                filetime:this.updateFormat(new Date()),
                uploadPath: ''
            }
        },
        watch: {
            dialogVisibles: { // 监听器
                deep: true,
                handler(v) {
                    if (!v) {
                        return
                    }
                    this.dialogVisible = v
                }
            },
            fileType:{
                deep:true,
                handler(v){
                   this.uploadPath =  urlPath + 'erp/file/upload?businessType=' + v
                    +'&access_token='+localStorage.getItem("accessToken")
                    if (!v) {
                        return
                    }
                    this.businessType = v
                }
            },
            fileId:{
                deep:true,
                handler(v){
                    if (!v) {
                        return
                    }
                    this.id = v
                }
            },
        },
        methods:{
            openDialog(val){
                this.$emit("openDialog",val)
            },
            removeFile(res){
                this.removeStr =  res.response.data.fileName + ',' + res.response.data.filePath
                var attachmentLists = this.attachmentListStr.split("|");
                for (var i in attachmentLists) {
                    if (this.removeStr == attachmentLists[i] || attachmentLists[i] == '' || attachmentLists[i] == null){
                        attachmentLists.splice(i,1);
                    }
                }
                this.attachmentListStr = attachmentLists.join('|')
            },
            getData(res){
                if (res.code == 200 ){
                    this.attachmentListStr = this.attachmentListStr
                        + res.data.fileName + ","  + res.data.filePath + "|"
                }else {
                    this.$message.error(res.message)
                }
            },
            getErrorData(res){
                this.$message.error(res.message)
            },
            saveFiles(){
                this.dialogVisible = false;
                this.$refs.upload.clearFiles();
                this.$api.saveFile({
                    businessId: this.id,
                    attachmentListStr:this.attachmentListStr
                }).then(res => {
                    if (res.code == 200){
                        this.$message.success(res.message)
                    }else {
                        this.$message.error(res.message)
                    }
                })
                this.attachmentListStr = ''
                this.openDialog()
            },
            cancelUpload(){
                this.$refs.upload.clearFiles();
                this.dialogVisible = false;
                this.filename = '';
                this.attachmentList = [],
                this.openDialog()
                this.attachmentListStr = ''
            },
            updateFormat(date){
                var date = new Date(date);
                var Y = date.getFullYear() + '-';
                var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
                var D = (date.getDate()< 10 ? '0' + (date.getDate()) : date.getDate()) + ' ';
                var h = (date.getHours()< 10 ? '0' + (date.getHours()) : date.getHours()) + ':';
                var m = (date.getMinutes()< 10 ? '0' + (date.getMinutes()) : date.getMinutes()) + ':';
                var s = (date.getSeconds()< 10 ? '0' + (date.getSeconds()) : date.getSeconds());
                return Y + M + D + h + m + s;
            }
        }
    }
</script>

<style lang="scss" scoped>
    .input-box{
        margin: 5px 30px;
    }
</style>
