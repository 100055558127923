import service from "./request";
import da from "element-ui/src/locale/lang/da";

//登录
export const login = (data) => {
    return service.post('auth/oauth/token', data)
}
export const logout = (data) => {
        return service.post('auth/auth/exit', data)
    }
    //验证token
export const checkToken = (data) => {
    return service.get('auth/auth/principal', data)
}

/**
 * 菜单
 */
/*获取菜单信息*/
export const getMenu = (data) => {
        return service.get('auth/home/menuAndUser', data)
    }
    //获取父级菜单列表
export const getParentList = (data) => {
        return service.get('auth/sysMenu/parentList', data)
    }
    //获取分页菜单列表
export const getMenuList = (data) => {
        return service.get('auth/sysMenu/page', data)
    }
    //新增菜单
export const addMenu = (data) => {
        return service.post('auth/sysMenu/save', data)
    }
    //修改菜单
export const updateMenu = (data) => {
        return service.post('auth/sysMenu/update', data)
    }
    //修改菜单
export const deleteFunction = (data) => {
        return service.post('auth/sysMenu/delete', data)
    }
    //根据id查询菜单
export const findMenuById = (data) => {
        return service.get('auth/sysMenu/findById', data)
    }
    /**
     * 日志
     */
    /*获取日志信息*/
export const getLogs = (data) => {
        return service.get('auth/log/page', data)
    }
    /**
     * 用户
     */
    /*获取菜单信息*/
export const pageUser = (data) => {
        return service.get('auth/user/page', data)
    }
    /*保存菜单信息*/
export const saveUser = (data) => {
        return service.post('auth/user/save', data)
    }
    /*修改用户信息*/
export const updateUser = (data) => {
        return service.post('auth/user/update', data)
    }
    /*删除用户信息*/
export const deleteUser = (data) => {
        return service.post('auth/user/delete', data)
    }
    /*删除用户信息*/
export const getUserRole = (data) => {
    return service.get('auth/user/findById', data)
}

/*修改密码*/
export const updatePassword = (data) => {
    return service.post('auth/user/updatePass', data)
}
export const getSaleList = (data) => {
    return service.get('erp/common/salesmanList', data)
}

/**数据字典*/
//新增数据字典
export const saveDataDictionary = (data) => {
        return service.post("/auth/dictionary/save", data);
    }
    //修改数据字典
export const updateDataDictionary = (data) => {
        return service.post("/auth/dictionary/update", data);
    }
    //删除数据字典
export const deleteDataDictionary = (data) => {
        return service.post("/auth/dictionary/delete", data);
    }
    //显示数据字典
export const getDataDictionary = (data) => {
        return service.get("/auth/dictionary/dicTree", data);
    }
    //根据id查询数据字典
export const getDataDictionaryById = (data) => {
    return service.get("/auth/dictionary/findById", data);
}

/**
 * 角色
 */
/*获取权限配置信息*/
export const getPermission = (data) => {
        return service.get('auth/role/findById', data)
    }
    /*获取分页角色信息*/
export const pageRoles = (data) => {
        return service.get('auth/role/page', data)
    }
    /*新增分页角色信息*/
export const saveRoles = (data) => {
        return service.post('auth/role/save', data)
    }
    /*修改分页角色信息*/
export const updateRoles = (data) => {
        return service.post('auth/role/update', data)
    }
    /*修改分页角色信息*/
export const deleteRoles = (data) => {
    return service.post('auth/role/delete', data)
}

/**
 * 客户模块
 */

/*新增客户信息*/
export const saveCustomer = (data) => {
        return service.post('erp/customer/save', data)
    }
    /*修改客户信息*/
export const updateCustomer = (data) => {
        return service.post('erp/customer/update', data)
    }
    /*分页查询*/
export const pageCustomer = (data) => {
        return service.get('erp/customer/page', data)
    }
    /*根据客户id查询客户*/
export const getCustomerById = (data) => {
        return service.get('erp/customer/findById', data)
    }
    /*删除客户*/
export const deleteCustomer = (data) => {
        return service.post('erp/customer/delete', data)
    }
    /**
     * 功能
     */

/*新增功能信息*/
export const saveFuncList = (data) => {
        return service.post('erp/funcList/save', data)
    }
    /*修改功能信息*/
export const updateFuncList = (data) => {
        return service.post('erp/funcList/update', data)
    }
    /*分页查询*/
export const pageFuncList = (data) => {
        return service.get('erp/funcList/page', data)
    }
    /*删除功能*/
export const deleteFunList = (data) => {
        return service.post('erp/funcList/delete', data)
    }
    /*删除功能*/
export const getFuncListById = (data) => {
        return service.get('erp/funcList/findById', data)
    }
    /**
     * 功能
     */

/*特殊事项申请*/
//分页特殊申请
export const pageSpecialApprove = (data) => {
        return service.get('erp/applyContract/pageSpecial', data)
    }
    //根据id查询特殊事项详情
export const pageSpecialApproveById = (data) => {
        return service.get('erp/applyContract/specialDetail', data)
    }
    //审批特殊事项申请
export const approveSpecialApproval = (data) => {
    return service.post('erp/applyContract/specialAudit', data)
}

/*分页查询开票申请信息*/
export const pageApplyInvoice = (data) => {
        return service.get('erp/applyInvoice/page', data)
    }
    /*审批开票申请信息*/
export const approveApplyInvoice = (data) => {
        return service.post('erp/applyInvoice/audit', data)
    }
    /*审批开票申请信息*/
export const getApplyInvoiceById = (data) => {
    return service.get('erp/applyInvoice/findById', data)
}

/*新增试用申请信息*/
//分页查询
export const pageTestApproval = (data) => {
        return service.get('erp/applyContract/pageTest', data)
    }
    //根据id查询
export const getTestApprovalById = (data) => {
        return service.get('erp/applyContract/testDetail', data)
    }
    //生成试用协议
export const createTest = (data) => {
    return service.post('erp/applyContract/createTestContract', data)
}


/*新增合同申请信息*/
export const pageContractApproval = (data) => {
    return service.get('erp/applyContract/pageContract', data)
}
export const getContractApprovalById = (data) => {
    return service.get('erp/applyContract/contractDetail', data)
}
export const createContract = (data) => {
    return service.post('erp/applyContract/createContract', data)
}

/**补充协议*/

export const pageSupple = (data) => {
    return service.get('erp/applyContract/pageReplenish', data);
}
export const replenishAudit = (data) => {
    return service.post('erp/applyContract/replenishAudit', data)
}
export const updateSupplement = (data) => {
    return service.post('erp/applyContract/updateReplenish', data)
}
export const getSupplementById = (data) => {
    return service.get('erp/applyContract/replenishDetail', data)
}


/**合同管理*/
//合同管理-修改合同管理
export const updateContract = (data) => {
        return service.post('erp/contractDetail/updateContract', data)
    }
    //合同管理-分页查询合同管理
export const pageContract = (data) => {
        return service.get('erp/contractDetail/pageContract', data)
    }
    //合同管理-出库单
export const getContractShipping = (data) => {
        return service.get('erp/contractDetail/outList', data)
    }
    //合同管理-提交申请合同管理
export const passContract = (data) => {
        return service.post('erp/contractDetail/applyAudit', data)
    }
    //合同管理-获取详情信息
export const getContract = (data) => {
        return service.get('erp/contractDetail/contractDetail', data)
    }
    //合同管理-修改试用协议
export const updateProtocol = (data) => {
        return service.post('erp/contractDetail/updateTest', data)
    }
    //合同管理-分页查询试用协议
export const pageProtocol = (data) => {
        return service.get('erp/contractDetail/pageTest', data)
    }
    //合同管理-提交测试合同管理
export const passProtocol = (data) => {
        return service.post('erp/contractDetail/applyAuditTest', data)
    }
    //合同管理-测试协议转化成正式合同
export const convertContract = (data) => {
        return service.post('erp/contractDetail/convertContract', data)
    }
    //合同管理-获取测试协议详情
export const getTest = (data) => {
    return service.get('erp/contractDetail/testDetail', data)
}

//发货通知单详情
export const pageShipping = (data) => {
        return service.get('erp/contractDetail/pageNotice', data)
    }
    //发货通知单详情-合同
export const getShippingById = (data) => {
        return service.get('erp/contractDetail/noticeDetail', data)
    }
    /*获取回款信息单*/
export const pageGather = (data) => {
        return service.get('erp/recoveries/page', data)
    }
    // 获取设备信息
export const getDeviceList = (data) => {
        return service.get('ocs/deviceCharging/contractDevices', data)
    }
    // 获取所有设备信息
export const getAllDevicesList = (data) => {
        return service.get('ocs/deviceHistory/contractAllDevices', data)
    }
    /*获取添加回款时间和回款项*/
export const addCollection = (data) => {
        return service.post('erp/recoveries/addRecord', data)
    }
    /*获取回款信息详情*/
export const getGatherById = (data) => {
        return service.get('erp/recoveries/detail', data)
    }
    /*获取回款信息详情*/
export const recoveriesInvoicingRecord = (data) => {
    return service.get('erp/recoveries/recoveriesInvoicingRecord', data)
}

/**
 * 仓库管理
 */
/*分页发货单管理*/
export const pageDepotOut = (data) => {
        return service.get('erp/outList/page', data)
    }
    /*新增发货单管理*/
export const addDepotOut = (data) => {
        return service.post('erp/outList/add', data)
    }
    /*新增发货单管理*/
export const updateDepotOut = (data) => {
        return service.post('erp/outList/update', data)
    }
    /*获取发货单单详情*/
export const getOutdepotDetail = (data) => {
        return service.get('erp/outList/outListDetail', data)
    }
    /*获取发货通知单列表*/
export const getShippingList = (data) => {
        return service.get('erp/outList/noticeNumList', data)
    }
    /*获取发货通知单详情*/
export const getShippingDetail = (data) => {
        return service.get('erp/outList/findContractByNoticeNum', data)
    }
    /*获取发货合同id查询详情*/
export const getFillData = (data) => {
        return service.get('erp/outList/noticeNumDetail', data)
    }
    /*入库单*/
    //分页入库单管理
export const pageDepotIn = (data) => {
        return service.get('erp/warehouse/inStoragePage', data)
    }
    //新增入库单管理
export const addDepotIn = (data) => {
        return service.post('erp/warehouse/inStorage', data)
    }
    //修改入库单信息
export const updateDepotIn = (data) => {
        return service.post('erp/warehouse/updateInStorage', data)
    }
    //确认入库单信息
export const sureInDepot = (data) => {
        return service.post('erp/warehouse/sureInStorage', data)
    }
    //获取入库单详情
export const getInDepotDetail = (data) => {
        return service.get('erp/warehouse/inStorageDetail', data)
    }
    //获取采购列表
export const getBuyList = (data) => {
        return service.get('erp/warehouse/buyList', data)
    }
    //获取采购列表
export const getAfterList = (data) => {
        return service.get('erp/afterSales/getAfterSales', data)
    }
    //根据采购单查询采购信息
export const getBuyDetailList = (data) => {
        return service.get('erp/warehouse/materialListByBuyId', data)
    }
    /*出库单*/
    //分页入库单管理
export const pageOutWarehouse = (data) => {
        return service.get('erp/warehouse/outStoragePage', data)
    }
    //新增入库单管理
export const addOutWarehouse = (data) => {
        return service.post('erp/warehouse/outStorage', data)
    }
    //修改入库单信息
export const updateOutWarehouse = (data) => {
        return service.post('erp/warehouse/updateOutStorage', data)
    }
    //确认入库单信息
export const sureOutWarehouse = (data) => {
        return service.post('erp/warehouse/sureOutStorage', data)
    }
    //获取入库单详情
export const getOutWarehouseDetail = (data) => {
    return service.get('erp/warehouse/outStorageDetail', data)
}

//获取出库单详情打印
export const outStorageDetailPrint = (data) => {
    return service.get('erp/warehouse/outStorageDetailPrint', data)
}


//获取入库单详情打印
export const inStorageDetailPrint = (data) => {
    return service.get('erp/warehouse/inStorageDetailPrint', data)
}

/*物料清单*/
//分页物料清单列表
export const pagePackDetail = (data) => {
        return service.get('erp/packingList/page', data)
    }
    //修改物料清单信息
export const updatePackDetail = (data) => {
        return service.post('erp/packingList/update', data)
    }
    //确认物料清单出库
export const surePackDetail = (data) => {
        return service.post('erp/packingList/sureOutStorage', data)
    }
    //获取物料清单详情
export const getPackingDetail = (data) => {
    return service.get('erp/packingList/detail', data)
}

/*物料清单管理*/
//分页物料清单管理列表
export const pagePackingList = (data) => {
        return service.get('erp/packingList/managePage', data)
    }
    //新增物料清单管理
export const addPackingList = (data) => {
        return service.post('erp/packingList/manageAdd', data)
    }
    //修改物料清单管理信息
export const updatePackingList = (data) => {
        return service.post('erp/packingList/manageUpdate', data)
    }
    //删除物料清单管理信息
export const deletePackingList = (data) => {
        return service.post('erp/packingList/manageDelete', data)
    }
    //获取物料清单管理详情
export const getPackingListDetail = (data) => {
    return service.get('erp/packingList/manageDetail', data)
}

/**
 *审批记录
 */
/*合同审批记录*/
// 分页合同审批信息
export const pageAuditRecord = (data) => {
        return service.get('erp/auditRecord/page', data)
    }
    // 合同审批详情
export const getAuditRecordById = (data) => {
        return service.get('erp/auditRecord/detail', data)
    }
    /*开票申请*/
    // 分页开票申请审批信息
export const pageAuditInvoice = (data) => {
        return service.get('erp/auditRecord/invoicePage', data)
    }
    // 开票申请审批详情
export const getAuditInvoiceById = (data) => {
    return service.get('erp/auditRecord/invoiceDetail', data)
}

/**
 * 保存附件
 */
export const saveFile = (data) => {
    return service.post('erp/common/saveAttachment', data)
}


/*首页*/
//销售端首页
export const getSalesIndex = (data) => {
        return service.get('erp/index/indexData', data)
    }
    //销售排行
export const getSalesSort = (data) => {
        return service.get('erp/index/saleList', data)
    }
    //分页出货信息
export const getPageSend = (data) => {
        return service.get('erp/index/deviceOutPage', data)
    }
    //销售端分页页
export const getSalesIndexPage = (data) => {
    return service.get('erp/index/indexPage', data)
}

//解析测试协议路径
export const analysisAboutTest = (data) => {
        return service.post('erp/applyContract/updateTestApply', data)
    }
    //解析合同协议路径
export const analysisAboutContract = (data) => {
        return service.post('erp/applyContract/updateContractApply', data)
    }
    //解析补充协议路径
export const analysisAboutSupplement = (data) => {
    return service.post('erp/applyContract/updateReplenish', data)
}


/**
 * 供应商信息
 */
//新增供应商信息
export const saveSupplier = (data) => {
        return service.post('erp/supplier/save', data)
    }
    //修改供应商信息
export const updateSupplier = (data) => {
        return service.post('erp/supplier/update', data)
    }
    //删除供应商信息
export const deleteSupplier = (data) => {
        return service.post('erp/supplier/delete', data)
    }
    //获取供应商列表信息
export const getSupplier = (data) => {
        return service.get('erp/supplier/page', data)
    }
    //根据id获取供应商信息
export const getSupplierById = (data) => {
    return service.get('erp/supplier/findById', data)
}

/**
 * 物料管理
 */
//获取字典数据
export const getDictionaryData = (data) => {
        return service.get('erp/material/dicInfo', data)
    }
    //根据id获取物料信息
export const getMaterialById = (data) => {
        return service.get('erp/material/findById', data)
    }
    // 分页获取物料信息
export const getMaterial = (data) => {
        return service.get('erp/material/page', data)
    }
    //删除物料信息
export const deleteMaterial = (data) => {
        return service.post('erp/material/delete', data)
    }
    //新增物料信息
export const addMaterial = (data) => {
        return service.post('erp/material/save', data)
    }
    //修改物料信息
export const updateMaterial = (data) => {
    return service.post('erp/material/update', data)
}

/**
 * 仓库信息
 */
//主物料仓库信息
export const getMainMaterial = (data) => {
        return service.get('erp/warehouse/mainPage', data)
    }
    //研发物料仓库信息
export const getDevMaterial = (data) => {
        return service.get('erp/warehouse/developPage', data)
    }
    //外协仓库信息
export const getOutSourcing = (data) => {
        return service.get('erp/warehouse/outsourcingPage', data)
    }
    //售后仓库信息
export const getAfterSale = (data) => {
        return service.get('erp/warehouse/aftermarketPage', data)
    }
    //获取所属仓库物料信息
export const getMaterialList = (data) => {
        return service.get('erp/warehouse/materialList', data)
    }
    // 获取仓库的已有数据
export const getDataById = (data) => {
        return service.get('erp/warehouse/findById', data)
    }
    // 获取仓库的已有数据
export const saveCheckData = (data) => {
    return service.post('erp/warehouse/checkData', data)
}

/**
 * 生产管理-物料申购
 */
//根据id获取申购信息
export const getApplyMaterialById = (data) => {
        return service.get('erp/produce/applyDetail', data)
    }
    // 分页获取申购信息
export const getApplyMaterial = (data) => {
        return service.get('erp/produce/applyPage', data)
    }
    //删除申购信息
export const deleteApplyMaterial = (data) => {
        return service.post('erp/produce/deleteApply', data)
    }
    //新增申购信息
export const addApplyMaterial = (data) => {
        return service.post('erp/produce/saveApply', data)
    }
    //修改申购信息
export const updateApplyMaterial = (data) => {
        return service.post('erp/produce/updateApply', data)
    }
    //提交申购信息
export const submitApplyMaterial = (data) => {
        return service.post('erp/produce/applyAudit', data)
    }
    /**
     * 生产管理-采购管理
     */
    //根据id获取申购信息
export const getPurchaseOrderById = (data) => {
    return service.get('erp/produce/buyDetail', data)
}

export const getApplyMaterialListById = (data) => {
        return service.get('erp/produce/materialListByApplyId', data)
    }
    // 分页获取申购信息
export const getPurchaseOrder = (data) => {
        return service.get('erp/produce/buyPage', data)
    }
    // 分页获取申购信息
export const getPurchaseList = (data) => {
        return service.get('erp/produce/applyList', data)
    }
    //新增申购信息
export const addPurchaseOrder = (data) => {
        return service.post('erp/produce/saveBuy', data)
    }
    //修改申购信息
export const updatePurchaseOrder = (data) => {
        return service.post('erp/produce/updateBuy', data)
    }
    //提交申购信息
export const submitPurchaseOrder = (data) => {
        return service.post('erp/produce/buyAudit', data)
    }
    //结束采购
export const endPurchaseOrder = (data) => {
        return service.post('erp/produce/buyEnd', data)
    }
    //采购的导出 参数为起始时间和结束时间
export const applyDetailPrint = (data) => {
    return service.get('erp/produce/applyDetailPrint', data)
}

/*报表中心*/
// 财务报表
export const getFinancialReport = (data) => {
        return service.get('erp/report/page', data)
    }
    // 库存明细表
export const getInventoryReport = (data) => {
        return service.get('erp/report/inventoryPage', data)
    }
    // 库存明细表
export const getRevenueReport = (data) => {
    return service.get('erp/report/revenuePage', data)
}


/*OCS*/
//获取调试人员列表
export const getDebugPerson = (data) => {
        return service.get('ocs/person/page', data)
    }
    //新增调试人员
export const saveDebugPerson = (data) => {
        return service.post('ocs/person/save', data)
    }
    //删除调试人员
export const deleteDebugPerson = (data) => {
        return service.post('ocs/person/del', data)
    }
    //查询总公司列表
export const getCompanyNameList = (data) => {
    return service.get('ocs/person/list', data)
}

//获取转发设备列表
export const getForward = (data) => {
        return service.get('ocs/forward/page', data)
    }
    //新增转发设备
export const saveForward = (data) => {
        return service.post('ocs/forward/add', data)
    }
    //删除转发设备
export const deleteForward = (data) => {
        return service.post('ocs/forward/del', data)
    }
    //获取公司转发条件
export const getForwardCompany = (data) => {
        return service.get('ocs/forward/companyPage', data)
    }
    //保存公司转发条件
export const saveForwardCompany = (data) => {
        return service.post('ocs/forward/save', data)
    }
    //删除公司转发条件
export const deleteForwardCompany = (data) => {
    return service.post('ocs/forward/delCompany', data)
}

//获取计费设备
export const getChargingDevices = (data) => {
        return service.get('ocs/deviceCharging/page', data)
    }
    //获取计费设备
export const getSaleUsers = (data) => {
        return service.get('ocs/deviceCharging/saleUsers', data)
    }
    //获取合同下计费设备
export const getContractChargingDevices = (data) => {
        return service.get('ocs/deviceCharging/contractDevices', data)
    }
    //修改计费设备计费周期
export const updateChargingDevice = (data) => {
        return service.post('ocs/deviceCharging/update', data)
    }
    //修改计费设备免费月数
export const updateChargingDeviceFreeUseMonth = (data) => {
        return service.post('ocs/deviceCharging/updateFreeUseMonth', data)
    }
    //停报计费设备
export const updateChargingDeviceStatus = (data) => {
        return service.post('ocs/deviceCharging/statusChange', data)
    }
    //终止计费设备
export const endChargingDevice = (data) => {
    return service.post('ocs/deviceCharging/end', data)
}

//获取计费设备历史
export const getChargingDevicesHistory = (data) => {
        return service.get('ocs/deviceHistory/page', data)
    }
    //获取计费设备操作历史
export const getChargingDeviceOperateInfo = (data) => {
    return service.get('ocs/deviceHistory/operateInfo', data)
}

//获取计费设备报表
export const getChargingDevicesReport = (data) => {
    return service.get('ocs/deviceReport/page', data)
}

//获取统计短信发送人员
export const getSmsSendUsers = (data) => {
        return service.get('ocs/smsSend/pagePerson', data)
    }
    //保存统计短信发送人员
export const saveSmdSendPerson = (data) => {
        return service.post('ocs/smsSend/save', data)
    }
    //删除统计短信发送人员
export const deleteSmdSendPerson = (data) => {
        return service.post('ocs/smsSend/delete', data)
    }
    //获取发送的统计短信
export const getSmsSendInfo = (data) => {
    return service.get('ocs/smsSend/page', data)
}


/*工程师信息管理*/
//获取工程师信息
export const pageEngineer = (data) => {
        return service.get('erp/engineer/page', data)
    }
    //保存工程师信息
export const saveEngineer = (data) => {
        return service.post('erp/engineer/add', data)
    }
    //修改工程师信息
export const updateEngineer = (data) => {
        return service.post('erp/engineer/update', data)
    }
    //删除工程师信息
export const deleteEngineer = (data) => {
        return service.post('erp/engineer/delete', data)
    }
    //根据id获取工程师信息
export const getEngineerById = (data) => {
        return service.get('erp/engineer/findById', data)
    }
    //获取工程师列表
export const getEngineerList = (data) => {
    return service.get('erp/engineer/getList', data)
}

/*新增售后申请/审批售后申请*/
//获取项目列表
export const getProjectList = (data) => {
        return service.get('erp/afterSales/chooseProject', data)
    }
    //获取设备信息
export const getDeviceListById = (data) => {
        return service.get('erp/afterSales/deviceList', data)
    }
    //新增售后申请
export const addAftermarket = (data) => {
        return service.post('erp/afterSales/add', data)
    }
    //修改售后申请
export const updateAftermarket = (data) => {
        return service.post('erp/afterSales/update', data)
    }
    //修改售后申请
export const uploadAftermarket = (data) => {
        return service.post('erp/afterSales/upload', data)
    }
    //获取售后分页
export const pageAftermarketList = (data) => {
        return service.get('erp/afterSales/page', data)
    }
    //根据id获取售后信息
export const getAftermarketById = (data) => {
        return service.get('erp/afterSales/findById', data)
    }
    // 提交售后申请
export const submitAftermarketById = (data) => {
        return service.post('erp/afterSales/apply', data)
    }
    // 提交售后申请
export const submitAftermarketByBusiness = (data) => {
        return service.post('erp/afterSales/applySure', data)
    }
    // 删除售后申请
export const delAftermarketById = (data) => {
        return service.post('erp/afterSales/delete', data)
    }
    // 审批售后申请
export const approveAftermarketById = (data) => {
    return service.post('erp/afterSales/applyAudit', data)
}

// 结束售后申请
export const endAftermarketById = (data) => {
        return service.post('erp/afterSales/finish', data)
    }
    // 结束售后申请
export const endSureAftermarketById = (data) => {
        return service.post('erp/afterSales/finishSure', data)
    }
    // 根据售后申请单ID查询出库物料
export const afterSalesOutListInfoById = (data) => {
    return service.get('erp/afterSales/afterSalesOutListInfo', data)
}


// 获取物料清单编号（模板）
export const listnumlist = (data) => {
    return service.get('erp/packingList/listnumlist', data)
}

// 获取售后申请审批情况
export const getApprovalRecord = (data) => {
    return service.get('erp/auditRecord/getApprovalRecord', data)
}

// 获取视频树
export const videoGroupTree = (data) => {
        return service.get('erp/video/videoGroupTree', data)
    }
    // 修改视频树
export const videoGroupSave = (data) => {
        return service.post('erp/video/videoGroupSave', data)
    }
    // 删除视频树
export const videoGroupDelete = (data) => {
        return service.post('erp/video/videoGroupDelete', data)
    }
    // 获取视频列表
export const videoPage = (data) => {
        return service.get('erp/video/videoPage', data)
    }
    // 编辑/新增视频
export const videoSave = (data) => {
    return service.post('erp/video/videoSave', data)
}

// 查看视频版本
export const videoVersionInfo = (data) => {
        return service.post('erp/video/videoVersionInfo', data)
    }
    // 视频升级
export const videoVersionUpgrade = (data) => {
        return service.post('erp/video/videoVersionUpgrade', data)
    }
    // 视频关闭加密
export const videoEncryptOff = (data) => {
    return service.post('erp/video/videoEncryptOff', data)
}
    // 视频镜像翻转
export const videoMirror = (data) => {
    return service.post('erp/video/videoMirror', data)
}

// 华西对账首页图表
export const indexHistory = (data) => {
        return service.get('ocs/hxDeviceCharging/indexHistory', data)
    }
    // 华西对账首页datainfo
export const indexThisMonth = (data) => {
        return service.get('ocs/hxDeviceCharging/indexThisMonth', data)
    }
    // 华西对账历史账单
export const historyPage = (data) => {
        return service.get('ocs/hxDeviceCharging/historyPage', data)
    }
    // 华西对账本月账单
export const currentPage = (data) => {
        return service.get('ocs/hxDeviceCharging/page', data)
    }
    // 华西对账 回上年度押金
export const receiveDeposit = (data) => {
        return service.get('ocs/hxDeviceCharging/receiveDeposit', data)
    }
    // 华西对账 回月份应收款
export const receiveMoney = (data) => {
    return service.get('ocs/hxDeviceCharging/receiveMoney', data)
}