import Vue from 'vue'
//import VueRouter from 'vue-router'
import login from '../views/Login.vue'

Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'login',
        component: login
    },
    /* {
       path: '/about',
       name: 'About',
       // route level code-splitting
       // this generates a separate chunk (about.[hash].js) for this route
       // which is lazy-loaded when the route is visited.
       component: () => import(/!* webpackChunkName: "about" *!/ '../views/About.vue')
     },*/
    {
        path: '/login',
        name: '登录',
        component: () =>
            import ('../views/Login.vue')
    },
    {
        path: '/printShipping',
        name: '出库单打印',
        component: () =>
            import ('../views/prints/ShippingPrint.vue')
    },
    {
        path: '/componentPrint',
        name: '出库单打印',
        component: () =>
            import ('../views/prints/ComponentPrint.vue')
    },
    {
        path: '/MaterialPrint',
        name: '物料清单打印',
        component: () =>
            import ('../views/prints/MaterialPrint.vue')
    },
    {
        path: '/printAftermarket',
        name: '售后审批',
        component: () =>
            import ("../views/prints/AfterSalesPrint.vue")
    },
    {
        path: '/HxLogin',
        name: '华西对账-登录',
        component: () =>
            import ("../views/HxHome/HxLogin.vue")
    },
    {
        path: '/HxIndex',
        name: '华西对账-首页',
        component: () =>
            import ("../views/HxHome/HxIndex.vue"),
    },
    {
        path: '/HxCurrent',
        name: '华西对账-当前对账',
        component: () =>
            import ("../views/HxHome/HxCurrent.vue")
    },
    {
        path: '/HxHistory',
        name: '华西对账-历史对账',
        component: () =>
            import ("../views/HxHome/HxHistory.vue")
    },
    {
        path: '/index',
        name: '系统首页',
        component: () =>
            import ('../views/Index.vue'),
        redirect: '/index',
        children: [{
            path: '/bossIndex',
            name: '系统首页',
            component: () =>
                import ("../views/system/BossIndex.vue")
        }, {
            path: '/index',
            name: '系统首页',
            component: () =>
                import ("../views/system/StaffIndex.vue")
        }, {
            path: '/role',
            name: '角色管理',
            component: () =>
                import ("../views/system/Role.vue")
        }, {
            path: '/dataDictionary',
            name: '数据字典',
            component: () =>
                import ("../views/system/DataDictionary.vue")
        }, {
            path: '/function',
            name: '菜单管理',
            component: () =>
                import ("../views/system/Function.vue")
        }, {
            path: '/user',
            name: '用户管理',
            component: () =>
                import ("../views/system/User.vue"),
        }, {
            path: '/log',
            name: '日志管理',
            component: () =>
                import ("../views/system/Log.vue")
        }, {
            path: '/customer',
            name: '客户管理',
            component: () =>
                import ("../views/manage/Customer.vue")
        }, {
            path: '/message',
            name: '消息中心',
            component: () =>
                import ("../views/manage/Massage.vue")
        }, {
            path: '/supplier',
            name: '供应商管理',
            component: () =>
                import ("../views/manage/Supplier.vue")
        }, {
            path: '/engineer',
            name: '工程师管理',
            component: () =>
                import ("../views/manage/Engineer.vue")
        }, {
            path: '/financialReport',
            name: '财务报表',
            component: () =>
                import ("../views/report/FinancialReport.vue")
        }, {
            path: '/inventory',
            name: '库存明细表',
            component: () =>
                import ("../views/report/InventoryDetailsReport.vue")
        }, {
            path: '/revenue',
            name: '收入成本报表',
            component: () =>
                import ("../views/report/RevenueCostReport.vue")
        }, {
            path: '/payment',
            name: '付款申请',
            component: () =>
                import ("../views/finance/Payment.vue")
        }, {
            path: '/gather',
            name: '回款记录',
            component: () =>
                import ("../views/finance/GatherRecord.vue")
        }, {
            path: '/material',
            name: '物料管理',
            component: () =>
                import ("../views/depot/Material.vue")
        }, {
            path: '/commissioningPersonnel',
            name: '调试人员',
            component: () =>
                import ("../views/ocs/CommissioningPersonnel.vue")
        }, {
            path: '/deviceForward',
            name: '设备转发控制',
            component: () =>
                import ("../views/ocs/DeviceForward.vue")
        }, {
            path: '/financialCollection',
            name: '账单回款',
            component: () =>
                import ("../views/ocs/FinancialCollection.vue")
        }, {
            path: '/invoiceInfo',
            name: '平台信息',
            component: () =>
                import ("../views/ocs/InvoiceInfo.vue")
        }, {
            path: '/deviceCharginginfo',
            name: '设备计费信息',
            component: () =>
                import ("../views/ocs/DeviceCharginginfo.vue")
        }, {
            path: '/deviceChargingHistory',
            name: '设备计费历史',
            component: () =>
                import ("../views/ocs/DeviceChargingHistory.vue")
        }, {
            path: '/smsSendUser',
            name: '短信人员',
            component: () =>
                import ("../views/ocs/SmsSendUser.vue")
        }, {
            path: '/smsSendInfo',
            name: '短信历史',
            component: () =>
                import ("../views/ocs/SmsSendInfo.vue")
        }, {
            path: '/accountStatement',
            name: '销售回款对账单',
            component: () =>
                import ("../views/ocs/AccountStatement.vue")
        }, {
            path: '/outDepot',
            name: '发货单',
            component: () =>
                import ("../views/depot/OutDepot.vue")
        }, {
            path: '/outWarehouse',
            name: '出库单',
            component: () =>
                import ("../views/depot/OutWarehouse.vue")
        }, {
            path: '/inDepot',
            name: '入库单',
            component: () =>
                import ("../views/depot/InDepot.vue")
        }, {
            path: '/funcList',
            name: '功能配置',
            component: () =>
                import ("../views/depot/FuncList.vue")
        }, {
            path: '/funcList1',
            name: '功能配置',
            component: () =>
                import ("../views/depot/FuncList1.vue")
        }, {
            path: '/packingList',
            name: '物料清单管理',
            component: () =>
                import ("../views/depot/PackingList.vue")
        }, {
            path: '/pickingDetail',
            name: '物料清单',
            component: () =>
                import ("../views/depot/PickingDetail.vue")
        }, {
            path: '/purchaseOrder',
            name: '采购管理',
            component: () =>
                import ("../views/purchase/PurchaseOrder.vue")
        }, {
            path: '/materialPurchase',
            name: '物料申购',
            component: () =>
                import ("../views/purchase/MaterialPurchase.vue")
        }, {
            path: '/contract',
            name: '合同管理',
            component: () =>
                import ("../views/sales/Contract.vue")
        }, {
            path: '/shipping',
            name: '发货通知',
            component: () =>
                import ("../views/sales/Shipping.vue")
        }, {
            path: '/testProtocol',
            name: '试用协议',
            component: () =>
                import ("../views/sales/TestProtocol.vue")
        }, {
            path: '/testApproval',
            name: '试用申请',
            component: () =>
                import ("../views/sales/TestApproval.vue")
        }, {
            path: '/specialApproval',
            name: '特殊事项申请',
            component: () =>
                import ("../views/sales/SpecialApproval.vue")
        }, {
            path: '/supple',
            name: '补充协议申请',
            component: () =>
                import ("../views/sales/Supplement.vue")
        }, {
            path: '/contractApproval',
            name: '合同申请',
            component: () =>
                import ("../views/sales/ContractApproval.vue")
        }, {
            path: '/approveContract',
            name: '审批记录',
            component: () =>
                import ("../views/approve/ContractApprove.vue")
        }, {
            path: '/invoiceApproval',
            name: '开票申请',
            component: () =>
                import ("../views/sales/Invoice.vue")
        }, {
            path: '/approveInvoice',
            name: '开票审批记录',
            component: () =>
                import ("../views/approve/InvoiceApprove.vue")
        },  {
            path: '/devlopmentWarehouse',
            name: '研发物料仓库',
            component: () =>
                import ("../views/warehouse/DevlopmentWarehouse.vue")
        }, {
            path: '/outsourcingWarehouse',
            name: '外协仓库',
            component: () =>
                import ("../views/warehouse/OutsourcingWarehouse.vue")
        }, {
            path: '/mainWarehouse',
            name: '主物料仓库',
            component: () =>
                import ("../views/warehouse/MainWarehouse.vue")
        }, {
            path: '/partsSales',
            name: '主物料仓库',
            component: () =>
                import ("../views/sales/PartsSales.vue")
        }, {
            path: '/afterWarehouse',
            name: '售后仓库',
            component: () =>
                import ("../views/warehouse/AfterSaleWarehouse.vue")
        }, {
            path: '/aftermarket',
            name: '售后申请',
            component: () =>
                import ("../views/aftermarket/AftermarketApply.vue")
        }, {
            path: '/afterApprove',
            name: '售后审批',
            component: () =>
                import ("../views/aftermarket/AftermarketApprove.vue")
        }, {
            path: '/video',
            name: '视频管理',
            component: () =>
                import ("../views/video/Video.vue")
        }, ]
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

// router.beforeEach((to,  from,  next)  =>  {
//     if  (to.path  ==  "/login")  {
//         next();
//     } 
//     else  {
//         const  token  =  localStorage.getItem("accessToken")
//         if  (token)  {
//             next();
//         } 
//         else  {
//             router.push({
//                 path:   "/login"
//             });
//         }
//     }
// });

export default router