<template>
    <div class="tags" v-if="showTags">
        <ul>
            <li class="tags-li" v-for="(item,index) in tagsList" :class="{'active': isActive(item.href)}" :key="index">
                <router-link :to="item.href" class="tags-li-title">
                    {{item.title}}
                </router-link>
                <span class="tags-li-icon" @click="closeTags(index)"><i class="el-icon-close"></i></span>
            </li>
        </ul>
        <div class="tags-close-box">
            <el-dropdown @command="handleTags">
                <el-button size="mini" type="primary">
                    标签选项<i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
                <el-dropdown-menu size="small" slot="dropdown">
                    <el-dropdown-item command="other">
                        关闭其他
                    </el-dropdown-item>
                    <el-dropdown-item command="all">
                        关闭全部
                    </el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </div>
    </div>
</template>

<script>
    import bus from "./common/js/bus.js";

    export default {
        name: "tags",
        data() {
            return {
                tagsList: []
            }
        },
        methods:{

            isActive(href) {
                return href === this.$route.fullPath;
            },
            // 关闭单个标签
            closeTags(index) {
                const delItem = this.tagsList.splice(index, 1)[0];
                const item = this.tagsList[index] ? this.tagsList[index] : this.tagsList[index - 1];
                if (item) {
                    delItem.href === this.$route.fullPath && this.$router.push(item.href);
                }else{
                    var roleName = localStorage.getItem("roleName");
                    if (roleName == 'admin'
                        || roleName == 'manager'
                        || roleName == 'finance_supervisor'
                        || roleName == 'production_supervisor'
                        || roleName == 'product_supervisor'
                        || roleName == 'operations_supervisor'){
                        this.$router.push('/bossIndex')
                    }else {
                        this.$router.push('/index')
                    }
                }
            },
            // 设置标签
            setTags(route){
                const isExist = this.tagsList.some(item => {
                    return item.href === route.fullPath;
                })
                if(!isExist){
                    if(this.tagsList.length >= 8){
                        this.tagsList.shift();
                    }
                    this.tagsList.push({
                        title: route.name,
                        href: route.fullPath,
                        name: route.matched[1].components.default.name
                    })
                }
                bus.$emit('tags', this.tagsList);
            },
            handleTags(command){
                command === 'other' ? this.closeOther():this.closeAll();
            },
            closeOther(){
                var closeItem = this.tagsList.filter(item => {
                    return item.href === this.$route.fullPath;
                })
                this.tagsList = closeItem;
            },
            closeAll(){
                this.tagsList = [];
                var roleName = localStorage.getItem("roleName");
                if (roleName == 'admin'
                    || roleName == 'manager'
                    || roleName == 'finance_supervisor'
                    || roleName == 'production_supervisor'
                    || roleName == 'product_supervisor'
                    || roleName == 'operations_supervisor'){
                    this.$router.push('/bossIndex')
                }else {
                    this.$router.push('/index')
                }
            },
        },
        computed: {
            showTags() {
                return this.tagsList.length > 0;
            }
        },
        watch:{
            $route(newValue, oldValue){
                this.setTags(newValue);
            }
        },
        created(){
            this.setTags(this.$route);
        }
    }
</script>
<style lang="scss" scoped>
    .tags {
        position: relative;
        height: 30px;
        overflow: hidden;
        background: #fff;
        padding-right: 120px;
        box-shadow: 0 5px 10px #ddd;
    }

    .tags ul {
        box-sizing: border-box;
        width: 100%;
        height: 100%;
    }

    .tags-li {
        float: left;
        margin: 3px 5px 2px 3px;
        border-radius: 3px;
        font-size: 12px;
        overflow: hidden;
        cursor: pointer;
        height: 23px;
        line-height: 23px;
        border: 1px solid #e9eaec;
        background: #fff;
        padding: 0 5px 0 12px;
        vertical-align: middle;
        color: #666;
        -webkit-transition: all .3s ease-in;
        -moz-transition: all .3s ease-in;
        transition: all .3s ease-in;
    }

   .tags-li:not(.active):hover {
        background: #f8f8f8;
    }
   .tags-li.active{
       color: #fff;
       background: #409EFF;
   }

   .tags-li-title {
       float: left;
       max-width: 80px;
       overflow: hidden;
       white-space: nowrap;
       text-overflow: ellipsis;
       margin-right: 5px;
       color: #666;
       text-decoration: none;
   }
   .tags-li.active .tags-li-title {
       color: #fff;
   }
    .tags-close-box {
        position: absolute;
        right: 0;
        top: 0;
        box-sizing: border-box;
        padding-top: 1px;
        text-align: center;
        width: 110px;
        height: 30px;
        background: #fff;
        box-shadow: -3px 0 15px 3px rgba(0, 0, 0, .1);
        z-index: 10;
    }
</style>
