<template>
    <div class="header">
        <div class="logo" @click="jampIndex">
            <i class="iconfont1">&#xe635;</i>
            <div>易方汇智ERP</div>
        </div>
        <div class="header-right">
            <div class="header-user-con">
                <!--全屏显示-->
                <div class="btn-fullscreen" @click="openFullScreen">
                    <i class="iconfont2" v-if="fullScreen==false">&#xe652;</i>
                    <i class="iconfont2" v-if="fullScreen==true">&#xe651;</i>
                </div>
                <!--消息中心-->
                <!--<el-badge :is-dot="false">
                    <div class="btn-bell">
                        <el-tooltip
                                effect="dark"
                                :content="message?`有${message}条未读消息`:`消息中心`"
                                placement="bottom"
                        >
                            <router-link to="/message">
                                <i class="el-icon-bell"></i>
                            </router-link>
                        </el-tooltip>
                    </div>
                    <span class="btn-bell-badge" v-if="message"></span>
                </el-badge>-->
                <!--用户头像-->
                <div class="user-avator">
                    <i class="iconfont3">&#xe643;</i>
                </div>
                <el-dropdown class="user-name" @command="handleCommand">
                    <span class="el-dropdown-link">
                        {{username}}<i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <el-dropdown-menu>
                        <el-dropdown-item divided command="updatePassword">
                            修改密码
                        </el-dropdown-item>
                        <el-dropdown-item divided command="loginOut">
                            退出登录
                        </el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
        </div>
        <div class="update-dialog">
            <el-dialog
                    title="修改密码"
                    :visible.sync="dialogVisible"
                    width="30%" center>
                <el-form
                        :model="resetForm"
                        :rules="resetFormRules"
                        ref="resetForm"
                        status-icon
                        label-width="100px"
                >
                    <el-form-item label="旧密码：" prop="password">
                        <el-input type="password" v-model="resetForm.password" auto-complete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="新密码：" prop="newPassword">
                        <el-input type="password" v-model="resetForm.newPassword" auto-complete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="确认密码：" prop="newAgain">
                        <el-input type="password" v-model="resetForm.newAgain" auto-complete="off"></el-input>
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="dialogVisible = false">取 消</el-button>
                    <el-button type="primary" @click="updatePassword">确 定</el-button>
                </span>
            </el-dialog>
        </div>
    </div>
</template>

<script>
    import bus from "./common/js/bus.js";

    export default {
        name: "Header",
        props: ["nowScreen"],
        data() {
            var validatePass = (rule, value, callback) => {
                if (!value) {
                    callback(new Error("请输入新密码"));
                } else if (value.toString().length < 6 || value.toString().length > 18) {
                    callback(new Error("密码长度为6-18位"));
                } else {
                    callback();
                }
            };
            var validatePass2 = (rule, value, callback) => {
                if (value === "") {
                    callback(new Error("请再次输入密码"));
                } else if (value !== this.resetForm.newPassword) {
                    callback(new Error("两次输入密码不一致!"));
                } else {
                    callback();
                }
            };
            return {
                collapse: true,
                name: '',
                message: 1,
                username: '',
                fullScreen: false,
                dialogVisible: false,
                resetForm: {
                    password: "",
                    newPassword: "",
                    newAgain: ""
                },
                resetFormRules: {
                    password: [
                        {required: true, message: "请输入旧密码", trigger: 'blur'}
                    ],
                    newPassword: [
                        {required: true, validator: validatePass, trigger: 'blur'}
                    ],
                    newAgain: [
                        {required: true, validator: validatePass2, trigger: "blur"}
                    ]
                }
            }
        },
        methods: {
            logOut() {
                /*this.$api.logout({
                    access_token:localStorage.getItem('accessToken')
                }).then(
                    res => {
                        if (res.code == 200){
                            localStorage.removeItem("accessToken");
                            localStorage.removeItem("refreshToken");
                            localStorage.removeItem("username");
                            this.$router.push('/')
                        }else{
                            this.$message.error(res.message);
                        }
                    }
                )*/
                localStorage.removeItem("accessToken");
                localStorage.removeItem("refreshToken");
                localStorage.removeItem("username");
                localStorage.removeItem("menuTree");
                localStorage.removeItem("authorityList");
                localStorage.removeItem("isSalesman");
                this.$router.push('/')
            },
            jampIndex() {

            },
            collapseChange() {
                this.$emit("collpase", this.collapse);
                this.collapse = !this.collapse;
            },
            handleCommand(command) {
                if (command == 'loginOut') {
                    this.logOut();
                } else if (command == 'updatePassword') {
                    this.dialogVisible = true;
                    this.resetForm = {
                        password: "",
                        newPassword: "",
                        newAgain: ""
                    }
                }
            },
            //全屏事件
            openFullScreen() {
                this.$emit("openFull", this.fullScreen)
                let element = document.documentElement;
                if (this.fullScreen && document.body.scrollHeight == window.screen.height) {
                    if (document.exitFullscreen) {
                        document.exitFullscreen();
                    } else if (document.webkitCancelFullScreen) {
                        document.webkitCancelFullScreen();
                    } else if (document.mozCancelFullScreen) {
                        document.mozCancelFullScreen();
                    } else if (document.msExitFullScreen) {
                        document.msExitFullScreen();
                    }
                } else {
                    if (element.requestFullscreen) {
                        element.requestFullscreen();
                    } else if (element.webkitRequestFullScreen) {
                        element.webkitCancelFullScreen();
                    } else if (element.mozRequestFullScreen) {
                        element.mozRequestFullScreen();
                    } else if (element.msRequestFullscreen) {
                        element.msRequestFullscreen();
                    }
                }
                this.fullScreen = !this.fullScreen
            },
            updatePassword() {
                this.$api.updatePassword({
                    oldPass: this.resetForm.password,
                    newPass: this.resetForm.newPassword
                }).then(res => {
                    if (res.code == 200) {
                        this.$message.success("修改密码成功")
                        this.logOut();
                    } else {
                        this.$message.error(res.message)
                    }
                })
            }
        },
        created() {
            var name = localStorage.getItem('username');
            this.username = name?name:''
        }
    }
</script>

<style lang="scss" scoped>
    .iconfont1 {
        font-family: "iconfont" !important;
        font-size: 45px;
        font-style: normal;
    }

    .iconfont2 {
        font-family: "iconfont" !important;
        font-size: 26px;
        font-style: normal;
    }

    .iconfont3 {
        font-family: "iconfont" !important;
        font-size: 28px;
        font-style: normal;
        font-weight: normal;
    }

    .header {
        box-sizing: border-box;
        width: 100%;
        height: 70px;
        font-size: 22px;
        color: #fff;
        background-color: #437EE6;
        display: flex;
    }

    .collapse-btn {
        float: left;
        padding: 0 20px;
        cursor: pointer;
        line-height: 70px;
    }

    .header .logo {
        width: 220px;
        height: 70px;
        font-size: 21px;
        margin-left: 1%;
        display: flex;
        align-items: center;
        justify-content: space-around;
    }

    .header-right {
        flex: 1;
        display: flex;
        justify-content: flex-end;
    }

    .header-user-con {
        display: flex;
        height: 70px;
        align-items: center;
    }

    .user-name {
        margin-left: 1%;
        margin-right: 10px;
        white-space: nowrap;
    }

    .el-dropdown-link {
        color: #FFFFFF;
        cursor: pointer;
    }

    .user-avator img {
        display: block;
        width: 40px;
        height: 40px;
    }

    .user-avator {
        margin: 0 15px;
    }

    .btn-bell, .btn-fullscreen {
        width: 30px;
        height: 30px;
        text-align: center;
        border-radius: 15px;
        cursor: pointer;
    }

    .btn-bell {
        margin-left: 20px;
    }

    .btn-bell, .el-icon-bell {
        color: #FFFFFF;
    }

    .btn-bell-badge {
        position: absolute;
        right: 0;
        top: -2px;
        width: 8px;
        height: 8px;
        border-radius: 4px;
        background: #F56C6C;
        color: #fff;
    }

    .btn-fullscreen {
        font-size: 24px;
    }

    .el-icon-screen {
        font-style: normal;
        height: 30px;
        width: 30px;
    }
</style>
