<template>
    <div class="pagination">
        <el-pagination
                @size-change="handleSizeChange"
                @current-change="handlePageChange"
                :current-page="currentPage"
                :page-sizes="pageList"
                :page-size="sizePage"
                layout="total, sizes, prev, pager, next"
                :total="pageTotal">
        </el-pagination>
        <!--<el-pagination
                background
                layout="total, prev, pager, next"
                :current-page.sync="currentPage"
                :page-size="sizePage"
                :total="pageTotal"
                @current-change="handlePageChange"
        ></el-pagination>-->
    </div>
</template>

<script>
    export default {
        name: "Pagination",
        props:['pageSize','pageTotal','pageCurrent','pageList'],
        data(){
            return{
                currentPage: this.pageCurrent,
                sizePage: this.pageSize
            }
        },
        watch: {
            pageCurrent: { // 监听器
                deep: true,
                handler(v) {
                    if (!v) {
                        return
                    }
                    this.currentPage = v
                }
            },
            sizePage: { // 监听器
                deep: true,
                handler(v) {
                    if (!v) {
                        return
                    }
                    this.pageSize = v
                }
            }
        },
        methods:{
            // 分页导航
            handlePageChange(val) {
                this.$emit('handlePageChange',val);
            },
            handleSizeChange(val){
                this.$emit('handleSizeChange',val);
            }
        }
    }
</script>

<style lang="scss" scoped>
    .pagination {
        margin:10px 0;
      //  text-align: center;
    }
</style>
