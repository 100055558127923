//import axios from 'axios';
import { Message } from "element-ui";
import { login } from "./index"
import router from '../../router/index'

var qs = require('qs');

axios.defaults.timeout = 10000;
axios.defaults.headers.post['Content-Type'] = 'application/json';
var urlPath = process.env.VUE_APP_CURENV;

var isRefresh = false;
var requestInfo = [];
const service = axios.create({
    baseURL: urlPath, //设置默认的URL前缀
    withCredentials: true, // send cookies when cross-domain requests
    timeout: 30000, // 设置请求超时。如果超过了60秒，就会告知用户当前请求超时，请刷新等
});

service.interceptors.request.use(config => {
    return config;
}, err => {
    Message.error({
        message: '请求超时!'
    });
    return Promise.resolve(err);
})


service.interceptors.response.use(
    data => {
        if (data && data.status == 200) {
            if (data.code = 200) {
                return data.data;
            } else {
                if (data && data.status == 400) {
                    Message.error({
                        message: '输入的数据有误!'
                    })
                }
            }
            return data.data;
        }
        return data.data;
    },
    err => {
        var baseURL = err.config.url;
        if (err && err.response) {
            switch (err.response.status) {
                case 400:
                    if (baseURL.match("token")) {
                        if (err.config.data.match("grant_type=refresh_token")) {
                            Message.error("用户在线时间过长，请重新登录")
                            const  roleName =  localStorage.getItem("roleName")
                            if (roleName == "hx_self" || roleName == "hx_jc") {
                                router.push("/HxLogin")
                            } else {
                                router.push("/")
                            }
                        } else {
                            Message.error("账号和密码不匹配，请重新输入");
                        }
                    } else {
                        Message.error("输入的数据有误，请联系管理员")
                    }
                    break;
                case 401:
                    if (!isRefresh) {
                        isRefresh = true;
                        return login({
                            grant_type: 'refresh_token',
                            refresh_token: localStorage.getItem("refreshToken"),
                            client_id: 'web_admin',
                            client_secret: 'web_admin'
                        }).then(res => {
                            if (res.access_token) {
                                localStorage.setItem("accessToken", res.access_token);
                                localStorage.setItem("refreshToken", res.refresh_token);
                                var a = res.access_token;
                                requestInfo.forEach((cb) => cb(a));
                                requestInfo = [];
                                var operUrl = urlPath + baseURL.split('?')[0] + "?access_token=" + a;
                                return axios({
                                    methods: err.config.method,
                                    url: operUrl,
                                    params: {
                                        ...err.config.data
                                    }
                                }).then(newRes => {
                                    return newRes.data;
                                });
                            } else {
                                Message.error("登录超时")
                                const  roleName =  localStorage.getItem("roleName")
                                if (roleName == "hx_self" || roleName == "hx_jc") {
                                    router.push("/HxLogin")
                                } else {
                                    router.push("/")
                                }
                            }
                        }).finally(() => {
                            isRefresh = false;
                        })
                    } else {
                        return new Promise(resolve => {
                            requestInfo.push(token => {
                                var operUrl = urlPath + baseURL.split('?')[0] + "?access_token=" + token;
                                resolve(axios({
                                    methods: err.config.method,
                                    url: operUrl,
                                    params: {
                                        ...err.config.data
                                    }
                                }).then(newRes => {
                                    return newRes.data;
                                }));
                            })
                        });
                    }
                    break;
                case 404:
                    {
                        Message.error("路径不正确，请联系管理员")
                        break;
                    }
                case 500:
                    {
                        Message.error("连接服务器失败，请联系管理员")
                        break;
                    }
                case 504:
                    {
                        Message.error("连接超时，请检查当前网络环境刷新页面")
                        break;
                    }
            }
        }
        return Promise.reject(err);
    });

export default {
    post(url, param) {
        this.dataTrim(param);
        return service({
            method: 'post',
            url: url,
            data: qs.stringify(param),
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                'Authorization': 'Bearer' + localStorage.getItem("accessToken")
            }
        })
    },
    get(url, param) {
        this.dataTrim(param);
        return service({
            method: 'get',
            url: url + '?' + qs.stringify(param),
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                'Authorization': 'Bearer' + localStorage.getItem("accessToken")
            }
        })
    },
    dataTrim(data) {
        for (var index in data) {
            var item = data[index];
            if (item === null || item === undefined) {
                continue;
            }
            if (typeof(item) === "string") {
                data[index] = item.trim();
            } else if (typeof(item) === "object") {
                this.dataTrim(item);
            }
        }
    }
};