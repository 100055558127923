<template>
    <div id="app">
        <router-view/>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                date: ''
            }
        },
        methods: {
            getTime() {
                let yy = new Date().getFullYear();
                let mm = new Date().getMonth() + 1;
                let dd = new Date().getDate();
                let hh = new Date().getHours() < 10 ? '0' + new Date().getHours() : new Date().getHours();;
                let mf = new Date().getMinutes() < 10 ? '0' + new Date().getMinutes() : new Date().getMinutes();
                let ss = new Date().getSeconds() < 10 ? '0' + new Date().getSeconds() : new Date().getSeconds();
                this.date = yy + '-' + mm + '-' + dd + ' ' + hh + ':' + mf + ':' + ss;
            },
            currentTime() {
                setInterval(this.getTime, 1000)
            },
        },
        created() {
            this.currentTime();
        },
        watch: {
            date: { // 监听器
                deep: true,
                handler(v) {
                    if (!v) {
                        return
                    }
                    if ((v + '').search('00:00:01') != -1) {
                        location.reload();
                    }
                }
            },
        }
    }

</script>

<style>
    @import "./assets/css/theme/main.css";
    @import "./assets/css/theme/color-dark.css";

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }

    input[type="number"] {
        -moz-appearance: textfield;
    }

    ::-webkit-scrollbar {
        width: 1px;
        height: 15px;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #a1a3a9;
        border-radius: 3px;
    }

</style>

