import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import JsonExcel from 'vue-json-excel'
import "./assets/css/icon/iconfont.css";

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

//引入Echarts
//import echarts from 'echarts';

//引入axios
import * as $api from './assets/api/index'

// 引入组件并注册
import Pagination from "./components/Pagination";
import Header from "./components/Header";
import Tags from "./components/Tags";
import UploadFiles from "./components/UploadFiles";
import './assets/css/icon/iconfont.css'

import  'docxtemplater/build/docxtemplater.js'
import  'pizzip/dist/pizzip.js'
import  'pizzip/dist/pizzip-utils.js'
import  'file-saver'

Vue.component("Pagination", Pagination)
Vue.component("Header", Header)
Vue.component("Tags", Tags)
Vue.component("Upload", UploadFiles)
Vue.component('download-excel', JsonExcel)

Vue.use(ElementUI)

Vue.config.productionTip = false
Vue.prototype.$echarts = echarts
Vue.prototype.$api = $api
Vue.prototype.hasAuthority = function(authority) {
    return localStorage.getItem("authorityList").indexOf(authority) >= 0;
};
new Vue({
    router,
    render: h => h(App)
}).$mount('#app')